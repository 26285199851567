import React from 'react';

import { BaseContentView, BaseProductView, BaseFiltersView } from '../core/views';

import { ProjectNameWidget } from '../core/components/projectNameWidget';
import { UsbDrivePreview } from './components/usbDrivePreview';
import { FiltersTitle } from '../core/components/filtersTitle';
import { EventTypesWidget } from './components/eventTypesWidget';
import { UsbDriveWidget } from './components/usbDriveWidget';
import { PackagingColorMaterialTypeWidget } from '../usb-drive-packaging/components/packagingColorMaterialTypeWidget';
import { PackagingUvGraphicWidget } from '../usb-drive-packaging/components/packagingUvGraphicWidget';
import { PackagingUvGraphicThemeWidget } from '../usb-drive-packaging/components/packagingUvGraphicThemeWidget';
import { PackagingUvGraphicElementWidget } from '../usb-drive-packaging/components/packagingUvGraphicElementWidget';
import { PackagingUvGraphicTextWidget } from '../usb-drive-packaging/components/packagingUvGraphicTextWidget';
import { PackagingUvPhotographerTextsWidget } from '../usb-drive-packaging/components/packagingUvPhotographerTextsWidget';
import { PackagingUvPhotographerNameWidget } from '../usb-drive-packaging/components/packagingUvPhotographerNameWidget';
import { PackagingGraphicElementColorsWidget } from '../usb-drive-packaging/components/packagingGraphicElementColorsWidget';
import { PackagingGraphicElementSuggestedColorsWidget } from '../usb-drive-packaging/components/packagingGraphicElementSuggestedColorsWidget';

class ProductView extends BaseProductView { //eslint-disable-line

  priceIsLoading() {
    let priceIsLoading;
    if (this.props.updatePrice) {
      priceIsLoading = false;
    } else {
      priceIsLoading =
        this.props.loadingCollection.usbDrivePackagingPrices ||
        this.props.productConfigurationIsLoading;
    }
    return priceIsLoading;
  }

  renderPreview() {
    return (
      <div className="row product-preview album-product-preview">
        <div className="col-xs-12">
          <UsbDrivePreview
            product={this.props.product}
            height={this.state.productPreviewHeight}
            width={this.state.productPreviewWidth}
          />
        </div>
      </div>
    );
  }
}

class FiltersView extends BaseFiltersView { //eslint-disable-line

  getFilters() {
    return [
      <FiltersTitle
        key="FiltersTitle"
        title={this.i18n.gettext('Personalizza il tuo usb & box')}
      />,

      <ProjectNameWidget
        key="ProjectNameWidget"
        delegate={this.delegate}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
      />,

      <EventTypesWidget
        key="EventTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.eventTypes}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <UsbDriveWidget
        key="UsbDriveWidget"
        delegate={this.delegate}
        items={this.props.filters.capacityTypes}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingColorMaterialTypeWidget
        key="PackagingColorMaterialTypeWidget"
        delegate={this.delegate}
        items={this.props.filters.colorMaterialTypes}
        selected={this.props.packagingConfiguration.colorMaterialType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicWidget
        key="PackagingUvGraphicWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicTypes}
        selected={this.props.packagingConfiguration.packagingUvGraphicType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicThemeWidget
        key="PackagingUvGraphicThemeWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicThemeTypes}
        selected={this.props.packagingConfiguration.packagingUvGraphicThemeType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicElementWidget
        key="PackagingUvGraphicElementWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicElementTypes}
        selected={this.props.packagingConfiguration.packagingUvGraphicElementType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvGraphicTextWidget
        key="PackagingUvGraphicTextWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicTextsCollection}
        value={this.props.packagingConfiguration.packagingUvGraphicTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingGraphicElementSuggestedColorsWidget
        key="PackagingGraphicElementSuggestedColorsWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicElementColorTypes}
        selected={this.props.packagingConfiguration.packagingUvGraphicElementColorType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingGraphicElementColorsWidget
        key="PackagingGraphicElementColorsWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvGraphicElementColorTypes}
        selected={this.props.packagingConfiguration.packagingUvGraphicElementColorType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvPhotographerNameWidget
        key="PackagingUvPhotographerNameWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvPhotographerNameTypes}
        selected={this.props.packagingConfiguration.uvPhotographerName}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PackagingUvPhotographerTextsWidget
        key="PackagingUvPhotographerTextsWidget"
        delegate={this.delegate}
        items={this.props.filters.packagingUvPhotographerTextsCollection}
        value={this.props.packagingConfiguration.uvPhotographerNameTexts}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />
    ];
  }
}

export class UsbDriveContentView extends BaseContentView {
  render() {
    return super.render(ProductView, FiltersView);
  }

  constructor(props) {
    super(props);
  }
}
