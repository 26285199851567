import React from 'react';
import BaseBootstrapWidget from '../../core/components/baseBootstrapWidget';

export class UsbDriveWidget extends BaseBootstrapWidget {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      showErrors: false
    };
  }

  async UNSAFE_componentWillReceiveProps() {
    let errors = [];
    this.setState({ errors: errors });
  }

  get name() {
    return 'capacityType';
  }

  get label() {
    return this.i18n.gettext('USB');
  }

  getUsbDriveDescription() {
    let description = [];
    if (this.props.items && this.props.items.map) {
      description = this.props.items.models[0].name;
    }
    return description;
  }

  getUsbDrivePreview() {
    let usbDrivePreviewUrl = [];
    if (this.props.items && this.props.items.map) {
      usbDrivePreviewUrl = this.props.items.models[0].fileUrl;
    }
    return usbDrivePreviewUrl;
  }

  renderContext() {
    return (
      <div className="row usb-drive">
        <div className="col-sm-12 padding-col-dx">
          {this.getUsbDriveDescription()}
          <img src={this.getUsbDrivePreview()} alt={''} />
        </div>
      </div>
    );
  }
}
