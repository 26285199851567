import { LoadableCollection } from '../core/managers';
import { conf } from 'outlinejs';
import { UsbDriveEventType, UsbDriveCapacityType } from '../usb-drive/models';

export class UsbDriveEventTypesCollection extends LoadableCollection {
  get name() {
    return 'eventTypes';
  }

  get url() {
    return conf.settings.USB_DRIVE_EVENT_TYPE_URL;
  }

  get model() {
    return UsbDriveEventType;
  }
}

export class UsbDriveCapacityTypesCollection extends LoadableCollection {
  get name() {
    return 'capacityTypes';
  }

  get url() {
    return conf.settings.USB_DRIVE_CAPACITY_URL;
  }

  get model() {
    return UsbDriveCapacityType;
  }
}
