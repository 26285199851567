import { BaseConfiguratorProductStorage } from '../core/storage/index';
import { UsbDriveConfiguration } from './models';
import { UsbDrivePackagingConfiguration } from '../usb-drive-packaging//models';

export class UsbDriveStorage extends BaseConfiguratorProductStorage {
  static get productPrefixKey() {
    return 'UsbDrive';
  }
}

export async function getUsbDriveConfiguration(configurationId, params) {
  let configuration = null;
  if (configurationId) {
    configuration = new UsbDriveConfiguration({ id: configurationId });
    if (params) {
      await configuration.fetch({ data: params });
    } else {
      await configuration.fetch();
    }
  }
  return configuration;
}

export class UsbDrivePackagingStorage extends BaseConfiguratorProductStorage {
  static get productPrefixKey() {
    return 'UsbDrivePackaging';
  }
}

export async function getUsbDrivePackagingConfiguration(configurationId, params) {
  let configuration = null;
  if (configurationId) {
    //TODO: rimuovere quando backend ha aggiunto uvGraphicColorSelected nella userConfiguration
    configuration = new UsbDrivePackagingConfiguration({ id: configurationId });
    if (params) {
      await configuration.fetch({ data: params });
    } else {
      await configuration.fetch();
    }
  }
  return configuration;
}
