import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import {
  UsbDriveController,
  UsbDriveCreateController,
  UsbDriveRestoreProjectController
} from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('usb-box:main', UsbDriveController),
      'new/': routing.url('usb-box:new', UsbDriveCreateController),
      new: routing.url('usb-box:new', UsbDriveCreateController),
      'edit/:projectId:': routing.url('usb-box:restoreProject', UsbDriveRestoreProjectController)
    };
  }
}
