import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';

import { GuiErrors, navigateTo } from '../core/utils/index';
import Logger from '../core/logger';
import { BaseConfiguratorController } from '../core/controllers';
import { usbDriveFunnelSteps } from '../core/utils/breadcrumb';
import { BaseLoadingView } from '../core/views';
import { Project } from '../projects/models';
import { UsbDriveContentView } from './views';
import { UsbDriveConfiguration } from './models';
import { UsbDrivePackagingConfiguration } from '../usb-drive-packaging/models';
import { UsbDriveEventTypesCollection, UsbDriveCapacityTypesCollection } from './managers';
import {
  UsbDrivePackagingColorMaterialTypesCollection,
  UsbDrivePackagingUvGraphicsCollection,
  UsbDrivePackagingUvGraphicThemeCollection,
  UsbDrivePackagingUvGraphicElementCollection,
  UsbDrivePackagingUvGraphicTextsCollection,
  UsbDrivePackagingUvPhotographerNameCollection,
  UsbDrivePackagingUvPhotographerTextsCollection,
  UsbDrivePackagingUvGraphicElementColorCollection,
  UsbDrivePackagingProductCollection,
  UsbDrivePackagingPriceCollection
} from '../usb-drive-packaging/managers';
import { cleanUsbDriveConfigurator } from '../core/storage/cleaners';
import { getUsbDrivePackagingConfiguration } from './utils';
import { PackagingConfigurationError, InvalidPackagingConfigurationError } from './errors';
import { createProfessionalPreOrder } from '../core/utils';
import { ProfessionalPreOrdersCollection } from '../core/managers';
//import {ProjectStorage as UsbDriveProjectStorage} from '../core/storage';
import { getProjectName, setProjectName } from '../projects/utils';
import EventTracker, { productCategoryInterface, productInterface } from '../core/eventTracker';

/*
 * Controller used to initialize a new matted prints configuration
 * */
export class UsbDriveCreateController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async init() {
    this.startInitialRendering();

    if (runtime.isClient) {
      // clean local forage data
      await cleanUsbDriveConfigurator();

      //create matted prints configuration
      let usbDriveConfiguration = await this.createUsbDriveConfiguration();

      if (usbDriveConfiguration) {
        navigateTo(this.request, this.response, 'usb-box:main', {}, true, {
          configurationId: usbDriveConfiguration.id,
          packagingConfigurationId: usbDriveConfiguration.packagingConfigurationId
        });
      } else {
        GuiErrors.modalError(
          this.i18n.gettext('Non è stato possibile creare la configurazione.'),
          'Error detail: UsbDriveCreateController init',
          null,
          null,
          this.redirectToHomepage.bind(this)
        );
      }
    }
  }

  async createUsbDriveConfiguration() {
    let usbDriveInitialConfiguration = conf.settings.USB_DRIVE_INITIAL_CONFIGURATION;
    let usbDriveConfiguration = new UsbDriveConfiguration();
    try {
      usbDriveConfiguration = await usbDriveConfiguration.save(usbDriveInitialConfiguration);
      Logger.info('Usb Drive Configuration id ', usbDriveConfiguration.id);
      return usbDriveConfiguration;
    } catch (err) {
      Logger.error('UsbDriveCreateController.createUsbDriveConfiguration', {
        error: err
      });
      return null;
    }
  }
}

/*
 * Controller used to restore a saved album configuration
 * */
export class UsbDriveRestoreProjectController extends BaseConfiguratorController {
  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async getProject(projectId) {
    return await new Project({ id: projectId }).fetch();
  }

  async getPreOrders(projectId) {
    return await new ProfessionalPreOrdersCollection().filterByProjectId(projectId);
  }

  async getUsbDrivePackagingConfiguration(id) {
    return await new UsbDrivePackagingConfiguration({ id: id }).fetch();
  }

  async redirectToUsbBoxNew() {
    navigateTo(this.request, this.response, 'usb-box:new', {}, true);
  }

  async init(projectId) {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      let usbDriveConfigurationId = null;
      let usbDrivePackagingConfigurationId = null;
      let project = null;
      let preOrders = null;

      let nextState = this.request.query['next-url']
        ? this.request.query['next-url']
        : 'usb-box:main';

      try {
        [project, preOrders] = await Promise.all([
          this.getProject(projectId),
          this.getPreOrders(projectId)
        ]);
      } catch (err) {
        if (err.code === 404) {
          GuiErrors.modalInfo(
            this.i18n.gettext(
              'Il prodotto che stai cercando è stato eliminato. Puoi procedere configurando un nuovo prodotto.'
            ),
            null,
            null,
            null,
            this.redirectToUsbBoxNew.bind(this)
          );
          return;
        } else {
          Logger.error(
            'UsbDriveRestoreProjectController.init - failed to fetch project or preorder',
            {
              error: err,
              projectId: projectId,
              project: project,
              preOrders: preOrders
            }
          );
          nextState = conf.settings.HOMEPAGE_BASE_URL;
        }
      }

      if (project && preOrders) {
        try {
          preOrders.forEach((preOrder) => {
            if (preOrder.productType === conf.settings.USB_DRIVE_PACKAGING_PRODUCT_TYPE) {
              usbDrivePackagingConfigurationId = preOrder.configurationId;
            }
          });

          let usbDrivePackagingConfiguration = await this.getUsbDrivePackagingConfiguration(
            usbDrivePackagingConfigurationId
          );
          usbDriveConfigurationId = usbDrivePackagingConfiguration.usbDriveConfigurationId;

          // set new configurator data
          await Promise.all([
            //UsbDriveProjectStorage.setName(project.name)
            setProjectName(project.name, conf.settings.USB_DRIVE_PRODUCT_TYPE)
          ]);
        } catch (err) {
          Logger.error('UsbDriveRestoreProjectController.init - failed to get configuration id', {
            error: err,
            project: project,
            preOrders: preOrders
          });
          nextState = conf.settings.HOMEPAGE_BASE_URL;
        }
      }

      navigateTo(this.request, this.response, nextState, {}, true, {
        configurationId: usbDriveConfigurationId,
        packagingConfigurationId: usbDrivePackagingConfigurationId,
        professionalProjectId: project.id
      });
    }
  }
}

export class UsbDriveController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get productType() {
    return 'UsbDrive';
  }

  get funnelStep() {
    return 1;
  }

  get funnelSteps() {
    return usbDriveFunnelSteps(this.request);
  }

  getCallToAction() {
    return this.i18n.gettext('Procedi');
  }

  getTitle() {
    return this.product && this.product.productItem && this.product.productItem.familyName
      ? this.product.productItem.familyName
      : this.i18n.gettext('Usb & Box');
  }

  get view() {
    return UsbDriveContentView;
  }

  /* extract (if possible) the default value from an item collection
   * and set it into configuration
   */
  setDefaultValueByItems(items, isPackagingWidget, bypassValidOption) {
    let defaultValue = null;
    let isValidOption = false;
    let selected = isPackagingWidget
      ? this.packagingConfiguration[items.model.modelName]
      : this.configuration[items.model.modelName];
    let configuration = isPackagingWidget ? this.packagingConfiguration : this.configuration;
    let productConfiguration = isPackagingWidget
      ? this.product.packagingProductConfiguration
      : this.product.productConfiguration;

    /* se nella collection c'è bypassDefault non impostare il default */
    if (items.length === 1 && !items.bypassDefault) {
      defaultValue = items.first().id;
    } else {
      items.each(function (item, $selected, $isValidOption, $defaultValue) {  //eslint-disable-line
        isValidOption = isValidOption || item.id === selected;
        // if (isPackagingWidget && items.model.modelName === 'packagingUvGraphicElementColorType' && !configuration.uvGraphicColorSelected) {
        if (bypassValidOption) {
          isValidOption = false;
        }
        if (item.isDefault) {
          defaultValue = item.id;
        }
      });
    }

    /* avoid update of disabled widgets */
    let disabledWidgets = this.getdisabledWidgets();
    let widgetDisabled = disabledWidgets.indexOf(items.model.modelName) > -1;
    if (widgetDisabled) {
      defaultValue = productConfiguration[items.model.modelName];
    }

    if (!isValidOption && defaultValue) {
      configuration[items.model.modelName] = defaultValue;
    }
  }

  get context() {
    return Object.assign(super.context, {
      product: this.product, // informazioni sul prodotto
      configuration: this.configuration, // stato dei filtri
      packagingConfiguration: this.packagingConfiguration, // stato dei filtri di packaging
      uvGraphicColorSelected: this.uvGraphicColorSelected,
      filters: this.filters, // collection dei filtri
      filtersViewValidity: this.filtersViewValidity,
      priceValidity: this.priceValidity,
      errorsVisibility: this.errorsVisibility,
      user: this.user,
      customerUser: this.customerUser,
      loadingCollection: this.loadingCollection,
      initViewRendering: this.initViewRendering,
      loadingPage: this.loadingPage,
      loadingPreview: this.loadingPreview,
      productConfigurationIsLoading: this.productConfigurationIsLoading,
      filtersViewIsLoading: this.filtersViewIsLoading,
      updatePrice: this.updatePrice,
      unitOfMeasure: this.unitOfMeasure
    });
  }

  async initContentProps() {
    super.initContentProps();

    // initialize content view properties
    this.product = {
      collection: new UsbDrivePackagingProductCollection(),
      productItem: {},
      previewUrl: null,
      productConfiguration: new UsbDriveConfiguration(),
      packagingProductConfiguration: new UsbDrivePackagingConfiguration(),
      price: {},
      showWidget: false,
      showWidgetPrice: this.customerUserIsAuthorized
    };

    this.graphicsLoaded = false;
  }

  async loadInitialConfiguration() {
    let configuration = {};
    let currentConfiguration;
    let configurationId = this.request.query.configurationId;

    if (configurationId) {
      Logger.info('Usb Drive ConfigurationId', configurationId);
      currentConfiguration = new UsbDriveConfiguration({ id: configurationId });

      try {
        await currentConfiguration.fetch();
      } catch (err) {
        if (err.code === 404) {
          GuiErrors.modalInfo(
            this.i18n.gettext(
              'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
            )
          );
          navigateTo(this.request, this.response, 'usb-box:new', {}, true, null);
          return false;
        } else {
          Logger.error(
            'UsbDriveController.loadInitialConfiguration - UsbDriveConfiguration FETCH',
            {
              error: err,
              id: configurationId
            }
          );
          return configuration;
        }
      }

      this.product.productConfiguration = currentConfiguration;
      Logger.info('restored configuration', currentConfiguration.toJSON());

      configuration = {
        formatType: '10x12',
        packagingType: 'DELUXE',
        packagingConfigurationId: currentConfiguration.packagingConfigurationId
      };

      this.configuration = configuration;
    } else {
      // if configurationId is not present redirect to albumepoca
      this.redirectToHomepage();
      return null;
    }
  }

  async loadInitialPackagingConfiguration() {
    try {
      let packagingConfigurationId = +this.request.query.packagingConfigurationId;
      let packagingConfiguration = await getUsbDrivePackagingConfiguration(
        packagingConfigurationId
      );

      if (!packagingConfiguration) {
        throw new InvalidPackagingConfigurationError('Impossible to load packaging configuration');
      }
      this.product.packagingProductConfiguration = packagingConfiguration;
      this.packagingConfiguration = packagingConfiguration.toJSON(); // la configurazione è un JSON (NON un backbone model
    } catch (error) {
      if (error.code === 404) {
        GuiErrors.modalInfo(
          this.i18n.gettext(
            'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
          )
        );
        this.response.navigate('usb-drive:new', {});
      } else {
        if (error instanceof InvalidPackagingConfigurationError) {
          let timestamp = new Date().getTime();
          GuiErrors.modalError(
            this.i18n.gettext(
              'Non è stato possibile caricare la configurazione della scatola, si prega di ripetere la procedura.'
            ),
            'Error detail: loadMattedPrintsPackagingConfiguration - code: ' + timestamp
          );
          navigateTo(this.request, this.response, 'usb-drive:main', {}, true, {
            packagingConfiguration:
              this.product.packagingProductConfiguration.usbDriveConfigurationId
          });
        } else {
          new PackagingConfigurationError(`UsbDrivePackagingController:init`, { //eslint-disable-line
            packagingConfiguration: this.packagingConfiguration,
            error: error
          });
        }
      }
    } finally {
      this.stopInitialRendering();
    }
  }

  async init() {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      let initialConfiguration = await this.loadInitialConfiguration();
      let initialPackagingConfiguration = await this.loadInitialPackagingConfiguration();

      this.stopInitialRendering();
      if (initialConfiguration !== null && initialPackagingConfiguration !== null) {
        await this.reloadView();
      }

      EventTracker.log(
        this.customerUser,
        'configurator_view',
        productInterface(this.product.packagingProductConfiguration)
      );
    }
  }

  setConfigurationTexts(name, code, value) {
    if (!this.packagingConfiguration[name]) {
      this.packagingConfiguration[name] = {};
    }
    this.packagingConfiguration[name][code] = value;
  }

  resetConfigurationTexts(name) {
    this.packagingConfiguration[name] = {};
  }

  setConfigurationItem(name, id, noReloadView, getPrice) {
    if (this.packagingConfiguration[name] !== id) {
      this.packagingConfiguration[name] = id;
      this.render(this.context);
      if (!noReloadView) {
        this.reloadView(getPrice);
      } else {
        //this.getPriceForCurrentConfiguration();
      }
    }
  }

  getParamsForCollectionWithStaticParams(collection, staticParams) {
    let params = {};
    for (let value of collection.loadParams) {
      /*HACK: the page size could be implicit*/
      if (!(value in staticParams)) {
        if (value === 'configurationId') {
          params[value] = this.product.packagingProductConfiguration.id;
        } else {
          params[value] = this.packagingConfiguration[value];
        }
        /* PATCH API filters if query filter param does not exists */
        if (!params[value]) {
          params[value] = 'null';
        }
      }
    }

    let mergedParams = Object.assign(params, staticParams);

    return mergedParams;
  }

  /*
   * load items of a ginven collection according to params then set the default item
   */
  async loadPackagingCollection(collection, type, staticParams, bypassValidOption) {
    this.startCollectionLoading(collection.name);
    let params = this.getParamsForCollectionWithStaticParams(collection, staticParams);
    let isPackagingWidget = true;
    if (type) {
      params.previousCoverColorMaterialType = type;
    }
    return collection
      .loadByParams(params)
      .then((items) => {
        this.filters[collection.name] = items;
        this.setDefaultValueByItems(items, isPackagingWidget, bypassValidOption);
        this.stopCollectionLoading(collection.name);
      })
      .catch((err) => {
        this.addFilterToErrorCollection(collection.name);
        Logger.error('Loading collection error', {
          error: err,
          params: params,
          module: collection.model.modelName
        });
      });
  }

  getPreviewTexts() {
    return this.filters.packagingUvGraphicElementTypes &&
      this.filters.packagingUvGraphicElementTypes.length === 0
      ? this.packagingConfiguration.packagingUvGraphicTexts
      : this.packagingConfiguration.packagingUvGraphicTexts;
  }

  async reloadView(getPrice) {
    this.productConfigurationIsLoading = true;
    this.filtersViewIsLoading = true;
    if (getPrice) {
      this.updatePrice = false;
    }
    this.render(this.context);
    try {
      this.resetFilterErrorsCollection();
      let staticParamas = { formatType: '10x12', packagingType: 'DELUXE' };

      await Promise.all([
        this.loadCollection(new UsbDriveEventTypesCollection()),
        this.loadCollection(new UsbDriveCapacityTypesCollection()),
        this.loadPackagingCollection(
          new UsbDrivePackagingColorMaterialTypesCollection(),
          null,
          staticParamas
        ),
        this.loadPackagingCollection(
          new UsbDrivePackagingUvPhotographerNameCollection(),
          null,
          staticParamas
        )
      ]);

      await this.loadProduct();

      await Promise.all([
        this.loadPackagingCollection(
          new UsbDrivePackagingUvGraphicsCollection(),
          null,
          staticParamas
        ),
        this.loadPackagingCollection(
          new UsbDrivePackagingUvPhotographerTextsCollection(),
          null,
          staticParamas
        )
      ]);

      await this.loadPackagingCollection(
        new UsbDrivePackagingUvGraphicThemeCollection(),
        null,
        staticParamas
      );

      await this.loadPackagingCollection(
        new UsbDrivePackagingUvGraphicElementCollection(),
        null,
        staticParamas
      );

      await Promise.all([
        this.loadPackagingCollection(
          new UsbDrivePackagingUvGraphicTextsCollection(),
          null,
          staticParamas
        ),
        this.loadPackagingCollection(
          new UsbDrivePackagingUvGraphicElementColorCollection(),
          null,
          staticParamas,
          !this.packagingConfiguration.uvGraphicColorSelected
        )
      ]);
    } catch (err) {
      Logger.error('UsbDriveController.reloadView-loadFilters', {
        error: err,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
    } finally {
      this.filtersViewIsLoading = false;
      this.render(this.context);
    }

    this.getPriceForCurrentConfiguration(getPrice);
  }

  async getPriceForCurrentConfiguration(getPrice = true) {
    if (this.filters === []) {
      let timestamp = new Date().getTime();
      Logger.error('UsbDriveController.getPriceForCurrentConfiguration', {
        error: 'Empty filters',
        errorCode: timestamp,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
      this.productConfigurationIsLoading = false;
      this.render(this.context);
    } else {
      try {
        this.loadProduct();
        if (getPrice) {
          this.loadPrice();
        }
      } catch (err) {
        Logger.error('UsbDriveController.getPriceForCurrentConfiguration', {
          error: err,
          configuration: this.configuration,
          productConfiguration: this.product.productConfiguration,
          filters: this.filters
        });
      } finally {
        this.productConfigurationIsLoading = false;
        this.updatePrice = true;
        this.render(this.context);
      }
    }
  }

  getConfigurationRequiredParams() {
    return {
      formatType: this.configuration.formatType,
      packagingType: this.configuration.packagingType
    };
  }

  getConfigurationOptionalParams() {
    let params = {};

    if (this.filters.colorMaterialTypes && this.filters.colorMaterialTypes.length > 0) {
      params.colorMaterialType = this.packagingConfiguration.colorMaterialType;
    }

    if (this.filters.packagingUvGraphicTypes && this.filters.packagingUvGraphicTypes.length > 0) {
      params.packagingUvGraphicType = this.packagingConfiguration.packagingUvGraphicType;
    }

    if (
      this.filters.packagingUvPhotographerNameTypes &&
      this.filters.packagingUvPhotographerNameTypes.length > 0
    ) {
      params.uvPhotographerName = this.packagingConfiguration.uvPhotographerName;
    }

    if (
      this.filters.packagingUvGraphicThemeTypes &&
      this.filters.packagingUvGraphicThemeTypes.length > 0
    ) {
      params.packagingUvGraphicThemeType = this.packagingConfiguration.packagingUvGraphicThemeType;
    }

    if (
      this.filters.packagingUvGraphicElementTypes &&
      this.filters.packagingUvGraphicElementTypes.length > 0
    ) {
      params.packagingUvGraphicElementType =
        this.packagingConfiguration.packagingUvGraphicElementType;
    }

    if (
      this.filters.packagingUvGraphicElementColorTypes &&
      this.filters.packagingUvGraphicElementColorTypes.length > 0
    ) {
      params.packagingUvGraphicElementColorType =
        this.packagingConfiguration.packagingUvGraphicElementColorType;
    }

    return params;
  }

  async loadProduct() {
    let requiredParams = this.getConfigurationRequiredParams();
    let productParams = Object.assign(requiredParams, this.getConfigurationOptionalParams());
    let product = await super.getProduct(new UsbDrivePackagingProductCollection(), productParams);
    if (product) {
      this.setConfigurationProduct(product);
    }
  }

  async setConfigurationProduct(productItem) {
    super.setConfigurationProduct(productItem);
    this.render(this.context);
  }

  async loadPrice() {
    let requiredParams = this.getConfigurationRequiredParams();
    requiredParams = Object.assign(requiredParams, {
      language: this.getLanguage(),
      shopCode: this.customerUserIsAuthorized ? this.customerUser.shopCode : null,
      usbDriveCapacityType: this.configuration.capacityType
    });

    let priceParams = Object.assign(requiredParams, this.getConfigurationOptionalParams());
    let price = await this.getPrice(new UsbDrivePackagingPriceCollection(), priceParams);
    if (price) {
      this.setConfigurationPrice(price);
    }
  }

  getPreparedConfiguration(configuration) {
    let shopcode = null;
    if (this.customerUserIsAuthorized) {
      shopcode = this.customerUser.shopCode;
    }

    if (!configuration) {
      configuration = this.configuration;
    }
    configuration.shopCode = shopcode;

    configuration.eventType =
      this.filters.eventTypes && this.filters.eventTypes.length > 0
        ? configuration.eventType
        : null;

    return configuration;
  }

  getPackagingPreparedConfiguration(packagingConfiguration) {
    let shopcode = null;
    if (this.customerUserIsAuthorized) {
      shopcode = this.customerUser.shopCode;
    }

    if (!packagingConfiguration) {
      packagingConfiguration = this.packagingConfiguration;
    }
    packagingConfiguration.shopCode = shopcode;

    packagingConfiguration.colorMaterialType =
      this.filters.colorMaterialTypes && this.filters.colorMaterialTypes.length > 0
        ? packagingConfiguration.colorMaterialType
        : null;
    packagingConfiguration.packagingUvGraphicType =
      this.filters.packagingUvGraphicTypes && this.filters.packagingUvGraphicTypes.length > 0
        ? packagingConfiguration.packagingUvGraphicType
        : null;
    packagingConfiguration.packagingUvGraphicThemeType =
      this.filters.packagingUvGraphicThemeTypes &&
      this.filters.packagingUvGraphicThemeTypes.length > 0
        ? packagingConfiguration.packagingUvGraphicThemeType
        : null;
    packagingConfiguration.packagingUvGraphicElementType =
      this.filters.packagingUvGraphicElementTypes &&
      this.filters.packagingUvGraphicElementTypes.length > 0
        ? packagingConfiguration.packagingUvGraphicElementType
        : null;
    packagingConfiguration.packagingUvGraphicTexts =
      this.filters.packagingUvGraphicTextsCollection &&
      this.filters.packagingUvGraphicTextsCollection.length > 0
        ? packagingConfiguration.packagingUvGraphicTexts
        : {};
    packagingConfiguration.uvPhotographerName =
      this.filters.packagingUvPhotographerNameTypes &&
      this.filters.packagingUvPhotographerNameTypes.length > 0
        ? packagingConfiguration.uvPhotographerName
        : null;
    packagingConfiguration.uvPhotographerNameTexts =
      this.filters.packagingUvPhotographerTextsCollection &&
      this.filters.packagingUvPhotographerTextsCollection.length > 0
        ? packagingConfiguration.uvPhotographerNameTexts
        : {};
    packagingConfiguration.packagingUvGraphicElementColorType =
      this.filters.packagingUvGraphicElementColorTypes &&
      this.filters.packagingUvGraphicElementColorTypes.length > 0
        ? packagingConfiguration.packagingUvGraphicElementColorType
        : null;
    return packagingConfiguration;
  }

  async saveProductConfiguration() {
    let currentConfiguration = this.getPreparedConfiguration();
    let productConfiguration = this.product.productConfiguration;
    let usbDriveConfiguration = await productConfiguration.save(currentConfiguration);
    Logger.info('Usb Drive Configuration id: ', this.product.productConfiguration.id);
    return usbDriveConfiguration;
  }

  async savePackagingProductConfiguration() {
    let currentConfiguration = this.getPackagingPreparedConfiguration();
    let packagingProductConfiguration = this.product.packagingProductConfiguration;
    let usbDrivePackagingConfiguration = await packagingProductConfiguration.save(
      currentConfiguration
    );
    Logger.info(
      'Usb Drive Packaging Configuration id: ',
      this.product.packagingProductConfiguration.id
    );
    return usbDrivePackagingConfiguration;
  }

  async getProductPreOrderList() {
    return await new ProfessionalPreOrdersCollection().filterByConfigurationIdAndProductType(
      this.request.query.configurationId,
      conf.settings.USB_DRIVE_PRODUCT_TYPE
    );
  }

  async getPackagingProductPreOrderList() {
    return await new ProfessionalPreOrdersCollection().filterByConfigurationIdAndProductType(
      this.request.query.packagingConfigurationId,
      conf.settings.USB_DRIVE_PACKAGING_PRODUCT_TYPE
    );
  }

  redirectToCart(projectId) {
    let url = `${conf.settings.CART_BASE_URL}cart/`;
    if (projectId) {
      url += `${projectId}`;
    }

    this.response.navigate(url, {});
  }

  async saveConfigurations() {
    let usbDriveConfiguration;
    let usbDrivePackagingConfiguration;

    try {
      [usbDriveConfiguration, usbDrivePackagingConfiguration] = await Promise.all([
        this.saveProductConfiguration(),
        this.savePackagingProductConfiguration()
      ]);
      return [usbDriveConfiguration, usbDrivePackagingConfiguration];
    } catch (err) {
      const timestamp = new Date().getTime();
      Logger.error('UsbDriveController.goToNextStep - saveProductConfiguration', {
        error: err,
        errorCode: timestamp,
        configuration: this.product.productConfiguration
      });
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile salvare la configurazione, si prega di ripetere la procedura.'
        ),
        'Error detail: saveProductConfiguration - code: ' + timestamp
      );
      return [undefined, undefined];
    }
  }

  async initPreOrders(configurationId, packagingConfigurationId) {
    let professionalProjectId = null;
    let framePreOrder;
    let framePreOrderId = null;
    let framePackagingPreOrderId;

    [framePreOrder, framePackagingPreOrderId] = await Promise.all([
      this.getFramePreOrder(configurationId),
      this.getFramePackagingPreOrderId(packagingConfigurationId)
    ]);

    if (framePreOrder) {
      framePreOrderId = framePreOrder.id;
      professionalProjectId = framePreOrder.projectId;
    }

    return [professionalProjectId, framePreOrderId, framePackagingPreOrderId];
  }

  async savePreOrders() {
    /** check if preorder already exist **/
    let productPreorderList = await this.getPackagingProductPreOrderList();
    let packagingProductPreorder = productPreorderList.first();

    if (packagingProductPreorder) {
      this.projectId = packagingProductPreorder.projectId;
      return [true, undefined];
    }

    /** create pre-orders **/
    try {
      let usbDrivePreorder = await createProfessionalPreOrder(
        this.customerUser,
        this.product.productConfiguration
      );

      this.projectId = usbDrivePreorder.projectId;

      await createProfessionalPreOrder(
        this.customerUser,
        this.product.packagingProductConfiguration,
        this.projectId
      );

      EventTracker.log(this.customerUser, 'bucket_add_success', {
        product_category: productCategoryInterface(
          this.product.packagingProductConfiguration.productType
        ),
        project_id: this.projectId
      });

      return [true, undefined];
    } catch (err) {
      const timestamp = new Date().getTime();
      Logger.error('UsbDriveController.goToNextStep - createPreOrder', {
        error: err,
        errorCode: timestamp,
        customerUser: this.customerUser,
        usbDriveConfiguration: this.product.productConfiguration,
        usbDrivePackagingConfiguration: this.product.packagingProductConfiguration
      });
      GuiErrors.modalError(
        this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
        'Error detail: createPreOrder - code: ' + timestamp,
        this.i18n.gettext('Chiudi'),
        null,
        null
      );

      return [false, err];
    }
  }

  async goToNextStep() {
    this.errorsVisibility = true;
    this.startLoadingPage();

    if (await this.checkWidgetErrors()) {
      let [usbDriveConfiguration, usbDrivePackagingConfiguration] = await this.saveConfigurations();

      if (!this.isUserLoggedIn()) {
        this.redirectToLoginPage();
        return null;
      }

      if (this.customerUser && !this.customerUser.shopCode) {
        let timestamp = new Date().getTime();
        this.stopLoadingPage();
        GuiErrors.modalError(
          this.i18n.gettext('User not authorized to add product to cart'),
          'Error detail: shopCode null - code: ' + timestamp,
          this.i18n.gettext('Chiudi'),
          null,
          null
        );
        return false;
      }

      if (usbDriveConfiguration && usbDrivePackagingConfiguration) {
        let [preOrderResult, error] = await this.savePreOrders();

        if (preOrderResult) {
          // update project name
          try {
            let projectName = await getProjectName(conf.settings.USB_DRIVE_PRODUCT_TYPE);
            let project = await new Project({ id: this.projectId }).fetch();
            project.name = projectName
              ? projectName
              : `${this.i18n.gettext('Project')} ` + this.getDateNow();
            await project.save();
          } catch (err) {
            const timestamp = new Date().getTime();
            Logger.error('UsbDriveController.goToNextStep - project.save failed', {
              error: err,
              errorCode: timestamp,
              projectId: this.projectId
            });
          }

          this.redirectToCart(this.projectId);
        } else {
          EventTracker.log(this.customerUser, 'bucket_add_error', {
            product_category: productCategoryInterface(
              this.product.packagingProductConfiguration.productType
            ),
            error_code: String(error)
          });
        }
      }
    }
    this.stopLoadingPage();
  }

  /*
   * Graphic UV left preview handling functions section
   */

  setPreviewImage(url) {
    this.product.previewUrl = url;
    this.render(this.context);
  }

  fixSvg(svg, texts, color) {
    this.fixSvgTexts(svg, texts);
    if (color) {
      this.fixSvgColor(svg, color);
    }

    /* save the svg xml element as ready to use text */
    let s = new XMLSerializer();
    return s.serializeToString(svg);
  }

  fixSvgColor(svg, color) {
    /*fix the svg color for all svg elements*/
    let allElements = svg.querySelectorAll('*');
    for (let i = 0; i < allElements.length; i++) {
      allElements[i].setAttribute('fill', color);
    }
  }

  fixSvgTexts(svg, texts) {
    if (
      this.isSvgTextTypedByUser() ||
      (this.getPreviewTexts() && Object.keys(this.getPreviewTexts()).length > 0)
    ) {
      let svgTextElements = svg.querySelectorAll('text');

      for (let i = 0; i <= svgTextElements.length; i++) {
        let svgTextElement = svgTextElements[i];

        if (svgTextElement) {
          let svgTextElementId = svgTextElement.getAttribute('id');

          if (svgTextElementId) {
            texts = this.getPreviewTexts()[svgTextElementId];
            let text = '';
            if (texts) {
              text = texts;
            }
            svgTextElement.childNodes[0].nodeValue = text;
          } else {
            console.error('SVG have text component without id');
          }
        }
      }
    }
  }

  async setPreviewSvg(svgUrl, texts, color = this.product.svgColor) {
    svgUrl = svgUrl || this.product.previewSvgUrl;
    if (svgUrl) {
      var request = new XMLHttpRequest();
      await request.open('GET', svgUrl, true);

      request.onload = function () {
        var data = request.responseXML;
        let svg = data.getElementsByTagName('svg')[0];
        this.product.previewSvg = this.fixSvg(svg, texts, color);
        this.render(this.context);
      }.bind(this);

      request.onerror = function (err) {
        Logger.error('setPreviewSvg: Error on SVG file retrieve', {
          url: svgUrl,
          error: err
        });
      };
      request.send();
    }
  }

  clearPreviewSvg() {
    this.product.previewSvgUrl = null;
    this.product.previewSvg = null;
  }

  chosePreviewSvg(svgUrl) {
    this.product.previewSvgUrl = svgUrl;
  }

  setPreviewSvgColor(color) {
    if (color) {
      this.product.svgColor = color;
    } else {
      this.product.svgColor = this.product.chosenSvgColor;
    }
  }

  chosePreviewSvgColor(color) {
    color = color || this.product.svgColor;
    this.product.chosenSvgColor = this.product.svgColor = color;
  }

  resetPreviewSvgColor() {
    this.product.svgColor = null;
    this.product.chosenSvgColor = null;
  }

  chosePreviewTexLimit(rowLimitMin, rowLimitMax) {
    this.product.previewTexLimitMin = rowLimitMin;
    this.product.previewTexLimitMax = rowLimitMax;
  }

  chosePreviewSvgText() {
    this.product.SvgTextTyped = true;
  }

  isSvgTextTypedByUser() {
    return this.product.SvgTextTyped;
  }

  /** END OF left preview handling functions section **/

  getdisabledWidgets() {
    return [];
  }
}
