import { BaseModel } from '../core/models';
import { conf } from 'outlinejs';

export class UsbDriveConfiguration extends BaseModel {
  // url

  getAbsoluteUrl() {
    return this.urlRoot + '/' + this.id;
  }

  get urlRoot() {
    return conf.settings.USB_DRIVE_CONFIGURATIONS_URL;
  }

  // properties

  get id() {
    return this.get('id');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get productType() {
    return this.get('productType');
  }

  set productType(value) {
    this.set('productType', value);
  }

  get productName() {
    return this.get('productName');
  }

  set productName(value) {
    this.set('productName', value);
  }

  get capacityType() {
    return this.get('capacityType');
  }

  set capacityType(value) {
    this.set('capacityType', value);
  }

  get eventType() {
    return this.get('eventType');
  }

  set eventType(value) {
    this.set('eventType', value);
  }

  get packagingConfigurationId() {
    return this.get('packagingConfigurationId');
  }

  set packagingConfigurationId(value) {
    this.set('packagingConfigurationId', value);
  }
}

/**------------------- lookups -------------------**/

export class UsbDriveEventType extends BaseModel {
  static get modelName() {
    return 'eventType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class UsbDriveCapacityType extends BaseModel {
  static get modelName() {
    return 'capacityType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }
}
